<!--The content below is only a placeholder and can be replaced.-->
<mat-toolbar color="secundary" >
  <mat-toolbar-row class="expanded-toolbar"> 
    <div class="containerTittle" >
      <span class="containerIcon"><mat-icon>account_balance</mat-icon></span>
      <a class="tittleToolbar" > {{title}} </a>
    </div>
     
     <div fxLayout="row" fxShow="false" fxHide  fxShow.gt-sm >
      <button mat-button routerLink="/login" class="buttonToolbar"  *ngIf="!isAuthenticated">Ingresar</button>
      <button mat-button mat-raised-button class="buttonToolbar" routerLink="/register" *ngIf="!isAuthenticated">Registro</button>
      <button mat-button class="buttonToolbar" routerLink="/account" *ngIf="isAuthenticated">Cuenta</button>
      <button mat-button mat-raised-button class="buttonToolbar" routerLink="/transaction" *ngIf="isAuthenticated">Transacciones</button> 
      <button mat-button class="buttonToolbar" *ngIf="isAuthenticated" (click)="logout()">Logout</button>
     </div>
     <button mat-button [mat-menu-trigger-for]="menu" fxHide fxShow.lt-md>
      <mat-icon>menu</mat-icon>
    </button>

    

  </mat-toolbar-row>
</mat-toolbar>

<mat-menu x-position="before" #menu="matMenu"> 
  <button mat-menu-item routerLink="/login" *ngIf="!isAuthenticated">Ingresar</button>
  <button mat-menu-item routerLink="/register" *ngIf="!isAuthenticated">Registro</button>
  <button mat-menu-item routerLink="/account" *ngIf="isAuthenticated">Cuenta</button>
  <button mat-menu-item routerLink="/transaction" *ngIf="isAuthenticated">Transacciones</button> 
  <button mat-menu-item *ngIf="isAuthenticated" (click)="logout()">Logout</button>
</mat-menu>





<router-outlet></router-outlet>